import React from 'react';
import { Box } from '@rebass/emotion';
import Layout from '../components/layout';
import SEO from '../components/seo';

import Heading from '../components/Heading';
import Wrap from '../components/Wrap';
import ProjectCards from '../modules/ProjectCards';

const Projects = () => (
  <Layout dark>
    <SEO title="Citra. Our Work" keywords={[`work`, `ux`, `product`, `design`, `studio`]} />
    <Box bg="lightBeige" style={{ position: 'relative' }} py={['12%', '6%']}>
      <Wrap flexWrap="wrap">
        <Box width={1} data-aos="fade-up">
          <Heading mb={[5, 6]} color="darkGray" fontSize={[5, 6, 7]} as="h2">Our Work</Heading>
        </Box>
        <Box width={1}>
          <ProjectCards />
        </Box>
      </Wrap>
    </Box>
  </Layout>
);

export default Projects;
